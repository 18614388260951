import { createRouter, createWebHashHistory } from 'vue-router'

const host = window.location.host
const parts = host.split('.')

const routes = [
    {
      path: '/temp_member',
      name: 'temp_member',

      component: () => import('../pages/tempmember/TempMemberView.vue')
    },
    {
      path: '/stamp',
      name: 'stamp',

      component: () => import('../pages/stamp/StampView.vue')
    },
    {
      path: '/menu',
      name: 'menu',

      component: () => import('../pages/menu/MenuView.vue')
    },
    {
      path: '/shop',
      name: 'shop',

      component: () => import('../pages/shop/ShopView.vue')
    },
    {
      path: '/coupon',
      name: 'coupon',

      component: () => import('../pages/coupon/CouponView.vue')
    },
    {
      path: '/alarm',
      name: 'alarm',

      component: () => import('../pages/alarm/AlarmView.vue')
    },
    {
      path: '/push',
      name: 'push',

      component: () => import('../pages/push/PushView.vue')
    },
    {
      path : '/',
      alias : ['/member'],
      name: 'MemberView',

      component: () => import('../pages/member/MemberView.vue')
    },

    {
      path: '/member_detail',
      name: 'member_detail',

      component: () => import('../pages/member/MemberDetailView.vue')
    },

    {
      path: '/shop_detail/:idx',
      name: 'shop_detail',

      component: () => import('../pages/shop/ShopDetailView.vue')
    },

    {
      path: '/shop_option/:idx',
      name: 'shop_option',

      component: () => import('../pages/shop/ShopOptionView.vue')
    },
    {
        path: '/shop_other/:idx',
        name: 'shop_other',

        component: () => import('../pages/shop/OtherView.vue')
    },


    {
      path: '/shop_order_list/:idx',
      name: 'shop_order_list',

      component: () => import('../pages/shop/OrderListView.vue')
    },

    {
        path: '/sales_status/:idx',
        name: 'sales_status',

        component: () => import('../pages/shop/SalesStatusView.vue')
    },
    {
        path: '/shop_no_order_menu/:idx',
        name: 'shop_no_order_menu',

        component: () => import('../pages/shop/ShopNoOrderMenuView.vue')
    },



  ]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
