import { createRouter, createWebHistory } from 'vue-router'
const routes = [

    {
        path: '/',
        name: 'pos_login',

        component: () => import('../pages/pos/LoginView.vue')
    },

    {
        path: '/shop_option',
        name: 'shop_option',

        component: () => import('../pages/shop/ShopOptionView.vue')
    },

    {
        path: '/shop_no_order_menu/:idx',
        name: 'shop_no_order_menu',

        component: () => import('../pages/shop/ShopNoOrderMenuView.vue')
    },

    {
        path: '/shop_order_list',
        name: 'shop_order_list',

        component: () => import('../pages/shop/OrderListView.vue')
    },

    {
        path: '/shop_order_cancel_list',
        name: 'shop_order_cancel_list',

        component: () => import('../pages/shop/OrderCancelListView.vue')
    },

    {
        path: '/sales_status/:idx',
        name: 'sales_status',

        component: () => import('../pages/shop/SalesStatusView.vue')
    },

    {
        path: '/shop_other/:idx',
        name: 'shop_other',

        component: () => import('../pages/shop/OtherView.vue')
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
