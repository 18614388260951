<template>
  <div v-if="isLoading" class="preloader">
    <div class="loader"></div>
  </div>
</template>

<style scoped>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #722535;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

<script>
export default {
  name: 'Preloader',
  props: {
    isLoading: {
      type: Boolean,
      required: true
    }
  }
};
</script>