import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'index',

        component: () => import('../pages/user/Main.vue'),
        props: true
    },

    {
        path: '/main_test',
        name: 'index2',

        component: () => import('../pages/user/Main_test.vue'),
        props: true
    },

    {
        path: '/appmenu',
        name: 'appmenu',

        component: () => import('../components/AppMenuBar.vue'),
        props: true
    },

    {
        path: '/user/login',
        name: 'login',

        component: () => import('../pages/user/Login.vue'),
        props: true
    },


    {
        path: '/user/store_list',
        name: 'store_list',

        component: () => import('../pages/user/StoresView.vue'),
        props: true
    },

    {
        path: '/user/menu_category',
        name: 'menu_category',

        component: () => import('../pages/user/MenuCategoryView.vue'),
        props: true
    },

    {
        path: '/user/menu',
        name: 'user_menu',

        component: () => import('../pages/user/MenuView.vue'),
        props: true
    },

    {
        path: '/user/menu_option',
        name: 'user_menu_option',

        component: () => import('../pages/user/MenuOptionView.vue'),
        props: true
    },

    {
        path: '/user/cart',
        name: 'cart',

        component: () => import('../pages/user/CartView.vue'),
        props: true
    },

    {
        path: '/user/cart2',
        name: 'cart2',

        component: () => import('../pages/user/CartView2.vue'),
        props: true
    },

    {
        path: '/user/order_completed',
        name: 'order_completed',

        component: () => import('../pages/user/OrderCompletedView.vue'),
        props: true
    },

    {
        path: '/user/payment',
        name: 'payment',

        component: () => import('../pages/user/PaymentView.vue'),
        props: true
    },

    {
        path: '/user/stores',
        name: 'stores',

        component: () => import('../pages/user/StoresView.vue'),
        props: true
    },

    {
        path: '/user/stores_marked',
        name: 'stores_marked',

        component: () => import('../pages/user/Stores_marked.vue'),
        props: true
    },

    {
        path: '/user/card',
        name: 'card',

        component: () => import('../pages/user/CardView.vue'),
        props: true
    },

    {
        path: '/user/orderlist',
        name: 'orderlist',

        component: () => import('../pages/user/Orderlist.vue'),
        props: true
    },

    {
        path: '/user/receipt',
        name: 'receipt',

        component: () => import('../pages/user/Receipt.vue'),
        props: true
    },

    {
        path: '/user/charge_main',
        name: 'charge_main',

        component: () => import('../pages/user/Charge_main.vue'),
        props: true
    },

    {
        path: '/user/charge_now',
        name: 'charge_now',

        component: () => import('../pages/user/Charge_now.vue'),
        props: true
    },

    {
        path: '/user/charge_auto',
        name: 'charge_auto',

        component: () => import('../pages/user/Charge_auto.vue'),
        props: true
    },

    {
        path: '/user/charge_list',
        name: 'charge_list',

        component: () => import('../pages/user/Charge_list.vue'),
        props: true
    },

    {
        path: '/user/charge_refund',
        name: 'charge_refund',

        component: () => import('../pages/user/Charge_refund.vue'),
        props: true
    },

    {
        path: '/user/charge_refund_bank',
        name: 'charge_refund_bank',

        component: () => import('../pages/user/Charge_refund_bank.vue'),
        props: true

    },
    {
        path: '/user/pay_result',
        name: 'pay_result',

        component: () => import('../pages/user/Charge_result.vue'),
        props: true
    },
    {
        path: '/user/pay_result_new',
        name: 'pay_result_new',

        component: () => import('../pages/user/Charge_result_new.vue'),
        props: true
    },

    {
        path: '/user/favorite',
        name: 'favorite',

        component: () => import('../pages/user/Favorite.vue'),
        props: true
    },

    {
        path: '/user/gift_main',
        name: 'gift_main',

        component: () => import('../pages/user/Gift_Main.vue'),
        props: true
    },

    {
        path: '/user/gift_menu',
        name: 'gift_menu',

        component: () => import('../pages/user/Gift_Menu.vue'),
        props: true
    },


    {
        path: '/user/gift_view',
        name: 'gift_view',

        component: () => import('../pages/user/Gift_View.vue'),
        props: true
    },


    {
        path: '/user/gift_cart',
        name: 'gift_cart',

        component: () => import('../pages/user/Gift_CartView.vue'),
        props: true
    },


    {
        path: '/user/gift_taste',
        name: 'gift_taste',

        component: () => import('../pages/user/Gift_Taste.vue'),
        props: true
    },

    {
        path: '/user/gift_pay',
        name: 'gift_pay',

        component: () => import('../pages/user/Gift_Payment.vue'),
        props: true
    },

    {
        path: '/user/gift_pay_charge',
        name: 'gift_pay_charge',

        component: () => import('../pages/user/Gift_Pay_Charge.vue'),
        props: true
    },

    {
        path: '/user/gift_vou',
        name: 'gift_vou',

        component: () => import('../pages/user/Gift_Vou.vue'),
        props: true
    },

    {
        path: '/user/gift_accept/:gtype/:order_num',
        name: 'gift_accept',

        component: () => import('../pages/user/Gift_Accept.vue'),
        props: true
    },


    {
        path: '/user/gift_box',
        name: 'gift_giftbox',

        component: () => import('../pages/user/Gift_Giftbox.vue'),
        props: true
    },


    {
        path: '/user/gift_refund',
        name: 'gift_refund',

        component: () => import('../pages/user/Gift_Refund.vue'),
        props: true
    },


    {
        path: '/user/gift_giftbox_sub1',
        name: 'gift_giftbox_sub1',

        component: () => import('../pages/user/Gift_Giftbox_sub1.vue'),
        props: true
    },

    {
        path: '/user/gift_giftbox_sub2',
        name: 'gift_giftbox_sub2',

        component: () => import('../pages/user/Gift_Giftbox_sub2.vue'),
        props: true
    },

    {
        path: '/user/gift_giftbox_sub3',
        name: 'gift_giftbox_sub3',

        component: () => import('../pages/user/Gift_Giftbox_sub3.vue'),
        props: true
    },

    {
        path: '/user/gift_giftbox_sub4',
        name: 'gift_giftbox_sub4',

        component: () => import('../pages/user/Gift_Giftbox_sub4.vue'),
        props: true
    },

    {
        path: '/user/giftbox_subdetail/:idx',
        name: 'giftbox_subdetail',

        component: () => import('../pages/user/Gift_Giftbox_subDetail.vue'),
        props: true
    },

    {
        path: '/user/autopay_result',
        name: 'autopay_result',

        component: () => import('../pages/user/AutoCharge_result.vue'),
        props: true
    },

    {
        path: '/user/gift_menu_option',
        name: 'gift_menu_option',

        component: () => import('../pages/user/GiftOptionView.vue'),
        props: true
    },

    {
        path: '/user/gift_payment',
        name: 'gift_payment',

        component: () => import('../pages/user/GiftPaymentView.vue'),
        props: true
    },

    {
        path: '/user/gift_completed',
        name: 'gift_completed',

        component: () => import('../pages/user/GiftCompletedView.vue'),
        props: true
    },

    {
        path: '/user/gift_refund_bank/:idx',
        name : 'gift_refund_bank',
        component: () => import('../pages/user/Gift_refund_bank.vue'),
        props: true

    }


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
    // 항상 맨 위로 스크롤
    return { top: 0 }
    },
})

export default router
