const host = window.location.host
const parts = host.split('.')

import { createApp, getCurrentInstance } from "vue";
import App from "./App";
import router from "./router";
import router2 from "./router/index_user";
import router3 from "./router/index_pos";
import store from "./store";
import axios from "axios";
import dayjs from 'dayjs';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Vue3Geolocation from 'vue3-geolocation';
import mitt from 'mitt';
import VueSimpleAlert from "vue3-simple-alert-next";

axios.defaults.paramsSerializer = function(paramObj) {
    const params = new URLSearchParams()
    for (const key in paramObj) {
        params.append(key, paramObj[key])
    }

    return params.toString()
}
axios.defaults.timeout = 100000;
//axios.defaults.baseURL = "http://localhost:8080";
axios.defaults.baseURL = window.location.protocol + "//"+window.location.host;
axios.defaults.port = ":"+window.location.port;
// 캐싱 방지
axios.defaults.headers.get['Cache-Control'] = 'no-cache';
axios.defaults.headers.get['Pragma'] = 'no-cache';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;

axios.interceptors.request.use((request) => {

    if(request.port != ":8000" && request.port != ":8100"){
        if(request.url.indexOf("/api/") > -1){
        //spring-boot api 호출의 경우
            request.baseURL = "http://handsorder.cafe24.com";
            request.url = request.url.replace("/api","");
        }else if(request.url.indexOf("/pos-api/") > -1){
        // 구 핸즈앱 api 호출
            request.baseURL = window.location.protocol+"//mng.handsnetwork.com";
            request.url = request.url.replace("/pos-api","/rest_free");
        }else if(request.url.indexOf("/app-api/") > -1){
        // 운영
            request.baseURL = "https://app.handsnetwork.com";
            request.url = request.url.replace("/app-api","/rest_free");
        }
    }

    request.headers.authorization = localStorage.getItem('authorization');

    return request;
})

const app = createApp(App);
app.config.globalProperties.axios = axios;
app.component('Datepicker', Datepicker);
const emitter = mitt();
app.config.globalProperties.emitter = emitter;

var setRouter;
if(parts[0] == "mng"){
    setRouter = router;
}else if(parts[0] == "app"){
    setRouter = router2;
}else if(parts[0] == "pos"){
    setRouter = router3;
}
app.use(dayjs);
app.use(Vue3Geolocation);
app.use(VueSimpleAlert, { confirmButtonText : "확인", cancelButtonText : "취소" });

window.app = app.use(store)
            .use(setRouter)
            .mount("#app");

app.config.globalProperties.$filters = {
    dateformat(value) {
        if(!value) return '';

        if(typeof value === 'string'){
            return value.substring(0,10);
        }
        return '';
    },
    numberformat(value){
        if(!value) return 0;
        return Number(value).toLocaleString();
    },
    getDate(d){
        var d = new Date(d),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    },
    getMonth(d){
        var d = new Date(d),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month].join('-');
    }
}
//window.app = app;