<template>
    <preloader :isLoading="isLoading" />
    <router-view ref="childRef"/>
</template>

<script>
import Preloader from '@/components/Preloader.vue';

export default {
    name : 'App',
    components: {
        Preloader
    },
    data() {
        return {
            isLoading: true
        }
    },
    mounted() {
        let scriptElement = document.createElement('script');
        scriptElement.setAttribute('src', '/js/connect.js');
        document.head.appendChild(scriptElement);

       
        window.addEventListener("load", () => {
            this.isLoading = false;
        });
    },
}
</script>

<style>
@import './assets/css/common.css';
@import './assets/css/handsAdmin.css';
@import './assets/css/reset.css';
@import './assets/css/main.css';

</style>
